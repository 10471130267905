import { ADD_TOPPINGS } from "./types";

const initState = {};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case ADD_TOPPINGS:
      return payload;

    default:
      return state;
  }
};
