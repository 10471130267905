import axios from "axios";
import { G_API_KEY } from "../Utils/Constants/Keys";

const BaseUrl = "https://zapio-admin.com";
// const BaseUrl = "http://192.168.0.21:1234";

export const getOutlets = (location) =>
  axios.post(`${BaseUrl}/api/front/outlet/OutletDetail/`, location);

export const getNearestOutlets = (location) =>
  axios.post(`${BaseUrl}/api/front/outlet/nearestOutlet/`, location);

export const postUserExperience = (data) =>
  axios.post(`${BaseUrl}/api/front/customer/rating/`, data);

export const getOutletsProducts = (outlet) =>
  axios.post(`${BaseUrl}/api/front/customer/menu_list_filter/`, outlet, {
    timeout: 60 * 1 * 1000,
  });

export const getProductCustomization = (id) =>
  axios.post(`${BaseUrl}/api/front/customer/customize_data/`, {
    p_id: id,
  });
export const getCategories = (data) => axios.post(`${BaseUrl}/api/front/customer/cat_list/`, data);

export const placeOrder = (data) => axios.post(`${BaseUrl}/api/front/order/place/`, data);

export const getPaymentDetails = (data) =>
  axios.post(`${BaseUrl}/api/front/payment/setting/`, data);

export const getCoupon = (code, cart, tax, company) =>
  axios.post(`${BaseUrl}/api/front/Couponcode/`, {
    coupon_code: code,
    tax,
    company,
    cart,
  });

export const trackOrder = (orderID) =>
  axios.get(`${BaseUrl}/api/front/trackorder/?order_id=${orderID}`);

export const getConfiguration = () => {
  const requestUrl = `${BaseUrl}/api/front/configuration?url=${
    process.env.NODE_ENV === "development"
      ? "aizo-ordering.netlify.app"
      : // "staging--aizo-ordering.netlify.app"
      window.location.hostname.includes("www.")
      ? window.location.hostname.split("www.")[1]
      : window.location.hostname
  }`;
  return axios.get(requestUrl);
};

export const getFeatureProduct = () => axios.get(`${BaseUrl}/api/front/feature_product/ `);

export const getRecommendedProducts = (data) =>
  axios.post(`${BaseUrl}/api/front/product/recommend/`, data);

export const getGoogleAnalytics = (data) =>
  axios.post(`${BaseUrl}/api/front/configuration/google/analytics/`, data);

export const verifyLocation = (data) => axios.post(`${BaseUrl}/api/front/distance/check/`, data);

export const orderLater = (data) => axios.post(`${BaseUrl}/api/front/order/latter/`, data);

export const getGoogleLocation = (location) =>
  axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=${G_API_KEY}`,
  );

export const getCctvUrl = (data) =>
  axios.post("https://zapio-admin.com/api/front/outlet/stream/", data);
export const getAllCctvUrl = () =>
  axios.get("https://zapio-admin.com/api/front/all/stream/listing/");

export const VerifyEmail = (data) => axios.post(`${BaseUrl}/api/front/order/otp/`, data);
export const verifyOtp = (data) => axios.post(`${BaseUrl}/api/front/otp/verify/`, data);
export const postMPIN = (data) => axios.post(`${BaseUrl}/api/front/order/mpin/`, data);

export const verifyMPIN = (data) => axios.post(`${BaseUrl}/api/front/mpin/verify/`, data);

export const getProfile = (data) => axios.post(`${BaseUrl}/api/front/customer/profile/`, data);
export const requestLoginWithGoogle = (data) => axios.post(`${BaseUrl}/api/front/login/`, data);

export const getOffers = (id) => axios.get(`${BaseUrl}/api/front/coupon/list?outlet_id=${id}`);
export const saveNewLocation = (data) => axios.post(`${BaseUrl}/api/v2/brand/outofrange/`, data);
export const getMenus = (data) => axios.post(`${BaseUrl}/api/front/listing/menu/`, data);
export const getPDF = () =>
  axios.get(
    "https://ap-south-1.aws.data.mongodb-api.com/app/pdf-upload-nagxo/endpoint/get_pdf?id=61badbe767850b2e664541cb",
  );
export const getAllOutlets = (data) => axios.post(`${BaseUrl}/api/front/outlet/alloutlets/`, data);
