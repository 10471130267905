import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./services/store";
import "react-datepicker/src/stylesheets/datepicker.scss";
import "./Utils/styles/index.scss";
import { FallbackLoader } from "./wrappers/GlobalLoader";

const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <Suspense fallback={<FallbackLoader />}>
    <Provider store={store({})}>
      <App />
      {/* <FallbackLoader /> */}
    </Provider>
  </Suspense>,
  document.getElementById("root"),
);

serviceWorker.unregister();
