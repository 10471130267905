import React, { useContext, createContext, useState, useEffect } from "react";
import { getConfiguration } from "../Network/ApiCalls";
import { getLocalOutlet, saveToLocalOutlet } from "./AppStorage";

const LoaderContext = createContext(null);
const AppDataContext = createContext(null);
const CustomizeContext = createContext(null);
const ScrollContext = createContext(null);

export const GLoaderProvider = ({ children }) => {
  const lState = useState({ config: {}, isLoading: false });

  return <LoaderContext.Provider value={lState}>{children}</LoaderContext.Provider>;
};
export const CustomizeProvider = ({ children }) => {
  const lState = useState({ productData: {}, show: false });
  return <CustomizeContext.Provider value={lState}>{children}</CustomizeContext.Provider>;
};
export const ScrollPositionProvider = ({ children }) => {
  const lState = useState({});
  return <ScrollContext.Provider value={lState}>{children}</ScrollContext.Provider>;
};
export const AppDataProvider = ({ children }) => {
  const [state, setState] = useState({
    outlet: null,
    config: null,
    charges: null,
    customizeData: null,
    alertSuccess: false,
    locationService: null,
    menuData: null,
    orderID: null,
  });
  useEffect(() => {
    setState((st) => ({ ...st, outlet: getLocalOutlet() }));
    getConfiguration().then((res) => {
      if (res.data && res.data.data && res.data.data?.length) {
        const { brand_name, logo } = res.data.data[0];
        document.title = brand_name;
        const l = document.createElement("link");
        l.rel = "shortcut icon";
        l.href = logo;
        document.head.appendChild(l);
      }
      if (res.data && res.data.data && res.data.data[0])
        document.documentElement.style.setProperty(
          "--dynamic-colour",
          res.data?.data[0]?.accent_color,
        );
      setState((st) => ({ ...st, config: res.data && res.data.data && res.data.data[0] }));
      if (res.data && res.data.data && res.data.data[1])
        setState((st) => ({ ...st, charges: res.data.data[1] }));
    });
  }, []);
  return <AppDataContext.Provider value={[state, setState]}>{children}</AppDataContext.Provider>;
};

export const useAppData = () => {
  const [state, setState] = useContext(AppDataContext);
  const handleChange = (data) => setState((st) => ({ ...st, ...data }));

  useEffect(() => {
    console.log(state);
  }, [state]);
  return {
    appData: state,
    updateOutlet: (outlet) => {
      saveToLocalOutlet(outlet);
      handleChange({ outlet });
    },
    updateMenuData: (menuData) => handleChange({ menuData }),
    setConfig: (config) => handleChange({ config }),
    setCustomizeData: (customizeData) => handleChange({ customizeData }),
    setAlertSuccess: (alertSuccess) => handleChange({ alertSuccess }),
    setSuccessOrderID: (orderID) => handleChange({ orderID }),
  };
};

export const useGLoader = () => {
  const [state, setState] = useContext(LoaderContext);
  useEffect(() => {
    getConfiguration().then((res) => {
      if (res.data && res.data.data && res.data.data?.length) {
        const { brand_name, logo } = res.data.data[0];
        document.title = brand_name;
        const l = document.createElement("link");
        l.rel = "shortcut icon";
        l.href = logo;
        document.head.appendChild(l);
      }
      console.log("nnnnnnnnnnnnnnnnvvvvvvvv", res, res.data);
      if (res.data && res.data.data && res.data.data[0])
        document.documentElement.style.setProperty(
          "--dynamic-colour",
          res.data?.data[0]?.accent_color,
        );
      setState((st) => ({ ...st, config: res.data && res.data.data && res.data.data[0] }));
    });
  }, []);
  return {
    ...state,
    toggleGLoad: (val) => setState((st) => ({ ...st, isLoading: val })),
    setConfig: (config) => state?.config,
  };
};

export const useCustomizeData = () => {
  const [state, setState] = useContext(CustomizeContext);
  return {
    state: { ...state },
    toggleShow: (show) => setState((st) => ({ ...st, show })),
    setData: (productData) => setState((st) => ({ ...st, productData })),
  };
};

export const useScrollPosition = (pageName) => {
  const [state, setState] = useContext(ScrollContext) || [];
  useEffect(() => {
    if (!pageName) throw Error("Page name is required");
  }, [pageName]);
  return {
    scrollPosition: state ? state[pageName] || 0 : 0,
    persistScrollPosition: (pos) => setState((st) => ({ ...st, [pageName]: pos })),
  };
};
