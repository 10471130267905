import React from "react";
import "../../Utils/styles/notif.css";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";

const ICON_SIZE = 20;
const svgStyle = {
  width: "40px",
  height: "38px",
  /* font-family: 'Montserrat'; */
  strokeWidth: "1",
};
const icons = [
  {
    icon: <FiAlertCircle size={ICON_SIZE} style={svgStyle} />,
    color: "#a16e6e",
  },
  { icon: <FiCheckCircle size={ICON_SIZE} style={svgStyle} />, color: "green" },
  { icon: <FiAlertCircle size={ICON_SIZE} style={svgStyle} />, color: "red" },
  {
    icon: <FiAlertCircle size={ICON_SIZE} style={svgStyle} />,
    color: "#a16e6e",
  },
];
export default function Container({ title, message, type = 0 }) {
  console.log("matchhhhhh", message, message.match(/required/g));
  const finalMsg = message.split("is required");
  return (
    <div className="notif" style={{ borderLeft: `4px solid ${icons[type].color}` }}>
      <span className="notif-icon" style={{ color: icons[type].color }}>
        {" "}
        {icons[type].icon}
      </span>
      <div className="det-cont">
        <p className="title" style={{ color: icons[type].color }}>
          {finalMsg}
        </p>
        {message && message.match(/required/g) ? (
          <p className="desc">Please fill this field</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
