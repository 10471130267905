import { ADD_TOTAL } from "./types";

const initState = {
  total: 0,
  totalTax: 0,
  discount: 0,
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case ADD_TOTAL:
      return payload;
    default:
      return state;
  }
};
