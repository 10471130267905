export const G_API_KEY = "AIzaSyCIDUSBqHPfkEssENT_X9vuWt5nzca8_W4";
// const payment = [
//   {
//     keyid: 'rzp_live_3e7GRjk52csKtx',
//     razor_secret: '',
//     c_name: 'Insta Restaurants Pvt.Ltd'
//   },
//   {
//     keyid: 'rzp_live_2RJan6yyn9TQvY',
//     razor_secret: 'Bc2VuRLyfDYoDG3vyMzOwVUK',
//     c_name: 'Sashimi Singh LLP'
//   }
// ]
