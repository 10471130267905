const types = {
  TOGGLE_DIALOG: "dialog close",
};

const initState = {};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_DIALOG:
      return { ...state, [payload]: !state[payload] };
    default:
      return state;
  }
};

export const actions = {
  toggle: (payload) => ({ type: types.TOGGLE_DIALOG, payload }),
};
