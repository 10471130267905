import { createStore, combineReducers } from "redux";
import cartReducer from "./Cart";
import toppingsReducer from "./Toppings";
import totalReducer from "./Total";
import themeReducer from "./Theme";
import authReducer from "./Authentication";

import dialogsReducer from "./dialogsReducer";

export default (initState) => {
  const store = createStore(
    combineReducers({
      cart: cartReducer,
      toppings: toppingsReducer,
      total: totalReducer,
      theme: themeReducer,
      dialogs: dialogsReducer,
      auth: authReducer,
    }),
    initState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
  store.subscribe(() => {
    // console.log('state:', store.getState())
  });
  return store;
};
