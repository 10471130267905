import { UPDATE_THEME } from "./types";

const initState = {
  accentColor: "#ffd600",
  textColor: "#000000",
  secondaryColor: "#ffab00",
};
// const initState = {
//   accentColor: '#222',
//   textColor: '#fff',
//   secondaryColor: '#000'
// }
export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_THEME:
      return payload;

    default:
      return state;
  }
};
