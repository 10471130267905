import React from "react";
import { toast } from "react-toastify";
import Container from "./Container";

export default class CustomnNotification {
  static success(message, content, timeout = 2000, id) {
    toast.success(
      ({ close }) => (
        <Container type={1} title={message || ""} message={(message ?? "") + (content ?? "")} />
      ),
      { autoClose: timeout, toastId: id },
    );
  }

  static alert(message, content, timeout = 2000) {
    toast.warning(
      ({ close }) => (
        <Container type={3} title={message || ""} message={(message ?? "") + (content ?? "")} />
      ),
      { autoClose: timeout },
    );
  }

  static default(message, content, timeout = 2000) {
    toast(
      ({ close }) => (
        <Container type={0} title={message || ""} message={(message ?? "") + (content ?? "")} />
      ),
      { autoClose: timeout },
    );
  }

  static error(message, content, timeout = 2000) {
    toast.error(
      ({ close }) => (
        <Container type={2} title={message || ""} message={(message ?? "") + (content ?? "")} />
      ),
      { autoClose: timeout },
    );
  }
}
