import CustomnNotification from "../../Components/Notification";
import StorageHelper from "../../Utils/StorageHelper";

const types = {
  ADD_PRODUCT: "Add product",
  DELETE_PRODUCT: "Delete product",
  CLEAR_CART: "Clear product",
  UPDATE_PRODUCT: "Update product",
  TOGGLE_CART: "toggle cart",
  INITIALIZE_CART: "init cart",
};

export const cartActions = {
  initializeCart: () => ({ type: types.INITIALIZE_CART, payload: null }),
  addProduct: (product) => ({
    type: types.ADD_PRODUCT,
    payload: { ...product, cart_id: `item-${new Date().getTime()}` },
  }),
  updateProduct: (product) => ({
    type: types.UPDATE_PRODUCT,
    payload: product,
  }),
  deleteProduct: (id) => ({ type: types.DELETE_PRODUCT, payload: id }),
  clearCart: () => ({ type: types.CLEAR_CART, payload: [] }),
  toggleCartVisibility: (visibility) => ({
    type: types.TOGGLE_CART,
    payload: visibility,
  }),
};

const initState = { visible: false, data: [] };

export default (state = initState, { type, payload }) => {
  let data = [];
  switch (type) {
    case types.INITIALIZE_CART: {
      data = StorageHelper.getCart() || [];
      return { ...state, data };
    }

    case types.ADD_PRODUCT:
      data = [...state.data, payload];
      StorageHelper.saveCart(data);
      CustomnNotification.success(
        "Item is added successfully to your cart",
        "",
        2000,
        payload.product_id,
      );
      return { ...state, data };

    case types.DELETE_PRODUCT:
      data = state.data.filter((p) => p.cart_id !== payload);
      StorageHelper.saveCart(data);
      return {
        ...state,
        data,
      };

    case types.UPDATE_PRODUCT:
      data = state.data.map((p) => {
        if (payload.cart_id === p.cart_id) return { ...p, ...payload };
        return p;
      });
      StorageHelper.saveCart(data);
      return {
        ...state,
        data,
      };
    case types.CLEAR_CART:
      StorageHelper.saveCart([]);
      return { ...state, data: [] };
    case types.TOGGLE_CART:
      return { ...state, visible: payload };
    default:
      return state;
  }
};
