import StorageHelper from "../../Utils/StorageHelper";

const types = {
  INITIALIZE_AUTH: "init auth",
  LOGIN: "Log In",
  LOGOUT: "Log Out",
};

export const authActions = {
  initializeAuth: () => ({ type: types.INITIALIZE_AUTH, payload: null }),
  logIn: (authDetail) => ({
    type: types.LOGIN,
    payload: { ...authDetail },
  }),
  logOut: () => ({
    type: types.LOGOUT,
    payload: [],
  }),
};

const initState = { data: [] };

export default (state = initState, { type, payload }) => {
  let data = [];
  switch (type) {
    case types.INITIALIZE_AUTH: {
      data = StorageHelper.getAuth() || [];
      return { ...state, data };
    }
    case types.LOGIN:
      data = [payload];
      StorageHelper.saveAuth(data);
      return { ...state, data };

    case types.LOGOUT:
      data = [];
      StorageHelper.saveAuth(data);
      return { ...state, data };

    default:
      return state;
  }
};
