import React, { useRef, useEffect, useState } from "react";
import { TweenMax } from "gsap";
import { GLoaderProvider, useGLoader } from "../Components/AppContext";

export const FallbackLoader = ({ glRef = null, config }) => (
  <div className="gl-loader">
    <div>
      {/* <img src={appHelpers.getLocalImage('gr.png')} /> */}
      {config && <img src={config} alt="logo" />}
      <svg>
        <circle r={20} cx="50%" cy="50%" />
      </svg>
    </div>
  </div>
);

const GlLoader = () => {
  const [visible, setVisible] = useState(false);
  const { isLoading, config } = useGLoader();
  const glRef = useRef(null);
  useEffect(() => {
    if (isLoading) setVisible(isLoading);
    else
      TweenMax.fromTo(
        glRef.current,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.2,
          onComplete: () => {
            setVisible(false);
          },
        },
      );
  }, [isLoading]);
  useEffect(() => {
    if (visible) TweenMax.fromTo(glRef.current, { opacity: 0 }, { opacity: 1, duration: 0.3 });
  }, [visible]);
  return visible ? <FallbackLoader glRef={glRef} config={config?.logo} /> : null;
};

export default function GlobalLoader({ children }) {
  return (
    <GLoaderProvider>
      <GlLoader />
      {children}
    </GLoaderProvider>
  );
}
