const keys = { cart: "aizo_cart", outlet: "aizo_outlet_cache" };

const getLSData = (key) => localStorage.getItem(key);
const saveToLS = (key, value) => localStorage.setItem(key, value);

export const getLocalCart = () => {
  const cart = getLSData(keys.cart);
  return cart ? JSON.parse(cart) : {};
};

export const getLocalOutlet = () => {
  const o = getLSData(keys.outlet);
  return o ? JSON.parse(o) : {};
};

export const saveToLocalOutlet = (outlet) => {
  // if (outlet) {
  //   const o = JSON.stringify(outlet)
  saveToLS(keys.outlet, outlet ? JSON.stringify(outlet) : outlet);
  // } else saveToLS(keys.outlet, o)
};

export const saveToLocalCart = (cart) => {
  if (cart) {
    const c = JSON.stringify(cart);
    saveToLS(keys.cart, c);
  }
};

export const removeLocalCartData = () => localStorage.removeItem(keys.cart);
export const removeLocalOutletData = () => localStorage.removeItem(keys.outlet);
