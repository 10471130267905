const storageKeys = {
  cart: "aizo_cart",
  auth: "user_details",
};

const getItem = (key) => (localStorage ? localStorage.getItem(key) : null);
const setItem = (key, data) => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  }
  return false;
};

export default {
  saveCart: (data) => setItem(storageKeys.cart, data),
  getCart: () => {
    const c = getItem(storageKeys.cart);
    return c ? JSON.parse(c) : null;
  },
  saveAuth: (data) => setItem(storageKeys.auth, data),
  getAuth: () => {
    const c = getItem(storageKeys.auth);
    return c ? JSON.parse(c) : null;
  },
};
